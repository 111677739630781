import { memo, useState, useMemo, useCallback } from 'react';

import { brandConfig } from 'brands';
import {
  Button,
  Message,
  Paragraph,
  ReimbursementBrief,
  ReimbursementCard,
  ReimbursementErrorsType,
  StepCard,
  StepCardButtons,
} from 'design-system';
import { get } from 'lodash';

import { SUBMIT_INVOICE } from 'consts/constants';
import { SelectOption } from 'design-system/Atoms/Select/Select.helper';
import {
  Assignment,
  CurrencyName,
  File,
  InvoiceCategory,
  InvoiceItem,
  StepActions,
  UnleashBudget,
} from 'types/types';
import { useGetUsersBasic } from 'utils/apiQueryHooks';
import { useToast } from 'utils/hooks';
import { useValidateReimbursements } from 'utils/useValidateReimbursements';

import { isBonusError, isBudgetError, isInputError } from './utils';

import styles from './Step3.module.scss';

export interface Step3Actions extends StepActions {
  assignments: Assignment[];
  setAmount: (amount: number) => void;
  currency: CurrencyName;
  reimbursements: InvoiceItem[];
  unleashBudget: UnleashBudget;
  unleashPlusInvoiceCategory: InvoiceCategory;
  createReimbursement: () => void;
  updateReimbursement: (invoiceItemId: number, invoiceItemData: Partial<InvoiceItem>) => void;
  removeReimbursement: (reimbursementId: number) => void;
  uploadReimbursementFile?: (invoiceItemId: number, formData: FormData) => Promise<File[]>;
  removeReimbursementFile?: (invoiceItemId: number, updatedFiles: File[]) => Promise<void>;
}

export const Step3 = memo<Step3Actions>(
  ({
    activeHandler,
    inactiveHandler,
    assignments,
    state,
    unleashBudget,
    currency,
    createReimbursement,
    updateReimbursement,
    removeReimbursement,
    uploadReimbursementFile,
    removeReimbursementFile,
    reimbursements,
  }) => {
    const toast = useToast();
    const [isCompleted, setIsCompleted] = useState(false);
    const totalUnleashBudget = unleashBudget?.total || 0;
    const availableUnleashBudget = totalUnleashBudget - (unleashBudget?.used || 0);
    const [errors, setErrors] = useState<ReimbursementErrorsType>({
      isOverUnleashLimit: false,
    });
    const [cancelExceedConfirm, setCancelExceedConfirm] = useState(false);
    const [unleashBudgetError, setUnleashBudgetError] = useState(false);
    const { renderWarnings, validateReimbursements } = useValidateReimbursements(unleashBudget);

    const { resolvedData: usersData } = useGetUsersBasic({ query: { isActive: true } });
    const referrals = useMemo(
      () =>
        usersData?.data?.map((user) => ({
          value: user?.id,
          label: user?.displayName,
          imageUrl: user?.avatarUrl,
        })),
      [usersData],
    ) as SelectOption[];

    const { CONTENT } = SUBMIT_INVOICE;
    const step = CONTENT[3];

    const onNextButtonClick = () => {
      setIsCompleted(true);
      activeHandler?.();
    };

    const onEditButtonClick = () => {
      setIsCompleted(false);
      inactiveHandler?.();
    };

    const step3Error = useMemo(
      () =>
        isInputError(reimbursements) ||
        isBudgetError(reimbursements, unleashBudget) ||
        isBonusError(reimbursements, unleashBudget) ||
        cancelExceedConfirm ||
        errors.isOverUnleashLimit ||
        unleashBudgetError,
      [
        reimbursements,
        unleashBudget,
        cancelExceedConfirm,
        errors.isOverUnleashLimit,
        unleashBudgetError,
      ],
    );

    const assignmentError = assignments.length === 0;

    const updateReimbursementInvoiceItem = useCallback(
      async (invoiceItemId: number, invoiceItemData: Partial<InvoiceItem>) => {
        try {
          const ok = validateReimbursements(reimbursements, {
            ...invoiceItemData,
            id: invoiceItemId,
          });
          if (!ok) {
            setErrors({ isOverUnleashLimit: true });
            return;
          } else {
            setErrors({ isOverUnleashLimit: false });
          }
          await updateReimbursement(invoiceItemId, invoiceItemData);
        } catch (err) {
          const errorMessage = get(err, 'payload.message') || 'Failed to update the reimbursement';
          toast({
            text: errorMessage,
            type: 'error',
          });
        }
      },
      [reimbursements, toast, updateReimbursement, validateReimbursements],
    );

    return (
      <StepCard
        step={step.step}
        title={step.title}
        description={(state === 'active' && step.description) || ''}
        state={state}
        bottomContent={
          state === 'active' || (state === 'inactive' && isCompleted) ? (
            <StepCardButtons
              state={step3Error && state === 'active' ? 'disabled' : state}
              text={state === 'active' ? 'Next' : 'Edit'}
              onClick={state === 'active' ? onNextButtonClick : onEditButtonClick}
            />
          ) : null
        }
        singleColumn
      >
        {state === 'active' ? (
          <>
            {brandConfig.flags.unleashEnabled ? (
              <Paragraph variant="ui-bold">
                Unleash+ budget available: {availableUnleashBudget.toLocaleString()} USD
              </Paragraph>
            ) : null}
            {reimbursements.map((reimbursement) => (
              <ReimbursementCard
                key={reimbursement.id}
                assignments={assignments}
                availableUnleashBudget={availableUnleashBudget}
                reimbursement={reimbursement}
                updateReimbursement={(...args) => {
                  setErrors({ isOverUnleashLimit: false });
                  updateReimbursementInvoiceItem(...args);
                }}
                currency={currency}
                referrals={referrals}
                onExceedConfirm={setCancelExceedConfirm}
                setUnleashBudgetError={setUnleashBudgetError}
                onDelete={() => {
                  setErrors({ isOverUnleashLimit: false });
                  removeReimbursement(reimbursement.id);
                }}
                onUploadFile={uploadReimbursementFile}
                onRemoveFile={removeReimbursementFile}
              />
            ))}
            <Button
              variant="secondary"
              layout="fluid"
              iconPosition="end"
              iconName="plus"
              as="button"
              type="button"
              className={styles.AddButton}
              onClick={createReimbursement}
              disabled={errors.isOverUnleashLimit}
            >
              Submit new claim
            </Button>
            {assignmentError && (
              <div>
                <Message type="ERROR">
                  Please confirm with your account manager that you have active assignments that
                  accepts reimbursements before adding a reimbursement item.
                </Message>
              </div>
            )}
          </>
        ) : (
          <>
            {reimbursements.map((reimbursement) => {
              const referralName = referrals?.find(
                (referral) => referral.value === Number(reimbursement.name),
              )?.label;
              return (
                <ReimbursementBrief
                  key={`${reimbursement?.id}-${reimbursement?.name}`}
                  currency={currency}
                  referralName={referralName}
                  reimbursement={reimbursement}
                />
              );
            })}
          </>
        )}
        {renderWarnings()}
      </StepCard>
    );
  },
);
