import { ComponentPropsWithoutRef, ComponentPropsWithRef, ElementType } from 'react';

import { SortDirection } from 'design-system';
import moment from 'moment';

import {
  CURRENCIES,
  INVOICE_ITEM_TYPES,
  INVOICE_PAYMENT_TYPE,
  INVOICE_STATUS,
  PAYMENT_METHOD,
  PROJECT_TYPES,
  RARITY_TYPES,
  STATUS,
  USER_ROLE,
  VACATION_REQUEST_STATUS,
  VAULT_ITEM_GOALS,
  VAULT_ITEM_TYPES,
} from 'consts/constants';

import { definitions } from './apiTypes';

export type Definitions = Record<string, any> & definitions;
export type Props<T extends ElementType> = ComponentPropsWithoutRef<T>;
export type PropsWithRef<T extends ElementType> = ComponentPropsWithRef<T>;

export type Json = null | boolean | number | string | Json[] | { [prop: string]: Json };
export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type ApiRequestObject<Response> = {
  error: Error | null;
  isLoading: boolean;
  response?: Response;
};

export type ApiRequestWaitForApiObject<
  Obj extends ApiRequestObject<any>
> = Obj extends ApiRequestObject<infer Response>
  ? Response extends { data: infer T }
    ? T
    : unknown
  : unknown;

export type InvoiceItemType = typeof INVOICE_ITEM_TYPES[keyof typeof INVOICE_ITEM_TYPES];

export type InvoiceStatusName = typeof INVOICE_STATUS[keyof typeof INVOICE_STATUS];

export type InvoicePaymentType = typeof INVOICE_PAYMENT_TYPE[keyof typeof INVOICE_PAYMENT_TYPE];

export type InvoiceStatus = {
  id: number;
  name: InvoiceStatusName;
};

export type UserVaultStash = {
  coins: number;
  stars: number;
};

export type InvoiceCategory = {
  id: number;
  name?: string;
  _accountingCode?: AccountingCode;
};

export type ExpenseCategory = {
  id: number;
  name?: string;
};

export type ExpenseCategorySelect = {
  value?: number;
  label?: string;
};

export type InvoiceItem = {
  id: number;
  _status: InvoiceStatus;
  quantity: number;
  rate: number;
  _typeId?: InvoiceItemType;
  name?: string;
  notes?: string;
  overTimeApprover?: string;
  addedAt?: string;
  paidAt?: string;
  usdPrice?: number;
  files?: File[];
  approvedAt?: string;
  submittedAt?: string;
  _project?: Project;
  _category?: InvoiceCategory | { id?: number; name?: string; _accountingCode?: AccountingCode };
  _expenseCategory?: ExpenseCategory | { id?: number; name?: string };
  _approvedBy?: UserApiModel;
  _paidBy?: UserApiModel;
  _unleashCategory?: UnleashCategory;
  details?: HarvestAPIDataType;
  isCreatedBySystem?: boolean;
};

export type InvoiceItemForm = Partial<InvoiceItem> & {
  termConditions?: boolean;
};

export type Match = {
  params: { [key: string]: any };
  isExact: boolean;
  path: string;
  url: string;
};

export type PaymentInfo = {
  message?: string;
  source?: string;
};

export type Invoice = {
  id: number;
  number: number;
  submittedAt?: string;
  dateFrom?: string;
  dateTo?: string;
  totalAmount?: number;
  approvedAt?: string;
  paidAt?: string;
  notes?: string;
  airwallexPaymentId?: string;
  paymentType?: InvoicePaymentType;
  isValid?: boolean;
  addedAt?: Date | null;
  _companyId?: number;
  _status?: InvoiceStatus;
  _invoiceItems?: InvoiceItem[];
  _answers?: Answer[];
  _owner?: User;
  _currency?: Currency;
  _companies?: CompanyModel[];
  _accountingData?: AccountingData;
  label?: string;
  hours?: number;
  paymentInfo?: PaymentInfo;
  isCreatedBySystem?: boolean;
};

export type InvoiceWithAnswers = GetArrayItem<Definitions, ['getAnswersInvoicesResponse', 'data']>;

export type Currency = {
  id: number;
  name: typeof CURRENCIES[keyof typeof CURRENCIES];
};

export type CurrencyName = NonNullable<Currency['name']>;

export type CompanyModel = {
  id: number;
  name: string;
  address: string;
  suffix: string;
  avatarUrl: string;
};

export type MoodBarAnswers = Array<Pick<Invoice, 'dateTo' | '_answers' | 'number'>>;

type VacationRequestStatus = {
  id: typeof VACATION_REQUEST_STATUS[keyof typeof VACATION_REQUEST_STATUS];
};

export type VacationRequest = {
  _status?: VacationRequestStatus;
  _owner?: User;
  _reviewedBy?: User;
  dateFrom?: string;
  dateTo?: string;
  details: string;
  id?: string | number;
};

export type Settings = {
  id: string;
  values: Json;
};

export type SessionModel = {
  id: string;
  validUntil: string;
  createdAt: string;
  updatedAt: string;
  _user: UserApiModel;
};

export type EventModel = {
  startDate: string | Date;
  finishDate: string | Date;
  name: string;
  link: string;
  isActive: boolean;
  order: number;
  id: number;
  _addedBy: User;
};

export type UnlockableBountyModel = {
  id: number;
  coinValue: number;
  title: string;
  coverImage?: {
    location: string;
  };
  description: string;
  _bountyPath?: {
    id: number;
    name: string;
  };
};
export type PrintfulVariant = {
  id: number;
  name: string;
  syncProductId?: number;
  inStock?: boolean;
  externalVariantId?: string;
};

export type VaultItemModel = {
  id: number;
  itemName: string;
  description: string;
  images?: ReadonlyArray<File>;
  sizeGuide?: Readonly<File>;
  coinPrice: number;
  _rarity?: { id: number; name: string };
  starPrice: number;
  stock: number;
  coverImage?: {
    location: string;
  };
  _printfulVariants?: PrintfulVariant[];
  _vaultItemType: { id: number; name: VaultItemTypes };
  _vaultItemGoal?: { id: number; name: VaultItemGoals };
  _vaultItemCollection?: {
    id: number;
    name: string;
    VaultItemVaultItemCollection?: { order: number };
  }[];
  _vaultItemPurchases?: VaultItemPurchase[];
  _whenStockIsZero: { id: number; name: string };
  numberOfPurchases?: number;
  desiredGoal?: number;
  singlePurchaseValue?: number;
  formUrl?: string;
  isActive?: boolean;
  lockReachingGoal?: boolean;
  order?: number;
  _team?: Team;
  _group?: UserGroup;
  _teamId?: number | null;
  _groupId?: number | null;
  _rarityId?: number;
  _vaultItemGoalId?: number;
  _vaultItemTypeId?: number;
  _vaultItemCollectionId?: number;
  _whenStockIsZeroId: number;
  _slackChannels?: SlackChannel[];
  _bountiesCollectionId?: number;
  _bountiesCollection?: BountiesCollection;
};

export type DigitalGiftCard = {
  sender: string;
  recipient: string;
  message: string;
};

export type GroupMember = {
  id: number;
  addedAt: string;
  _userGroupId: number;
  _userId: number;
  _user: User;
};

export type UsersToUserGroups = {
  id: number;
  name?: string;
  _admin?: User;
  _members?: GroupMember[];
};

export type UserRole = typeof USER_ROLE[keyof typeof USER_ROLE];

type UserRateInfo = {
  value: number;
  currency: string;
};

type UserRateHistory = {
  old: UserRateInfo;
  new: UserRateInfo;
  createdAt: string;
  reason?: string;
  _userId: number;
  _updatedById: number;
  _user?: User | null;
  _updatedBy?: User | null;
};

export type User = {
  id: number;
  displayName: string;
  email?: string;
  isActive?: boolean;
  isXWP?: boolean;
  darkMode?: boolean;
  shouldNotifyBirthday?: boolean;
  shouldNotifyAnniversary?: boolean;
  shouldNotifyCompletedBountiesCreatedByMe?: boolean;
  shouldNotifyWeeklyBounties?: boolean;
  avatarUrl?: string;
  countryCallingCode?: string;
  telephone?: string;
  dateOfBirth?: string;
  nationality?: string;
  livingCountryCode?: string;
  livingCity?: string;
  livingStreetAddress?: string;
  slackReminders?: boolean;
  slackId?: string;
  rate?: number;
  australianBusinessNumber?: string;
  registeredForGST?: boolean;
  dietaryRestrictions?: string;
  contractingOption?: 'individual' | 'company';
  contractingOptionCompany?: string;
  lastLoginAt?: string;
  createdAt?: string;
  unleashBudget?: UnleashBudget;
  tax?: number;
  harvestId?: string;
  productiveId?: string;
  transferwiseEmail?: string;
  timezone?: string;
  canUseHarvest?: boolean;
  canUseProductive?: boolean;
  xpApplicantId?: number;
  _assignedNotes?: AssignedNotes[];
  _assignedTickets?: UserTicket[];
  _currency?: Currency;
  _signedDocuments?: SignedDocument[];
  _role?: Role;
  _rates?: UserRateHistory[];
  _coins?: number;
  _type?: UserType;
  _emergencyContact?: EmergencyContact;
  _shirtSize?: ShirtSize;
  _team?: Team;
  _employmentType?: EmploymentType;
  _accountingData?: AccountingData;
  _shippingAddress?: ShippingAddress;
  _groups?: UsersToUserGroups[];
  _invoices?: InvoiceItem[];
  _assignments?: Assignment[];
  _xtcRep?: User;
  _typeId?: 'other' | 'developer' | null;
  contractingOptionCompanyAddress?: string;
  contractingOptionCompanyTaxID?: string;
  allow0Rate?: boolean;
  acceptedPrivacyPolicy?: boolean;
};

export type AssignedNotes = {
  id: number;
  description: string;
  isOpen: boolean;
  _typeId: string;
  _owner: {
    displayName: string;
  };
};

export type USER_TICKET_TYPE =
  | 'Community'
  | 'Other'
  | 'Personal'
  | 'X-Team'
  | 'Project'
  | 'Gift Request';

export type USER_TICKET_STATUS = 'Closed' | 'Open';

export type UserTicketComment = {
  id: number;
  content: string;
  _author: {
    id: number;
    displayName: string;
    avatarUrl?: string;
  };
  createdAt: string;
};

export type UserTicket = {
  id: number;
  description: string;
  type?: USER_TICKET_TYPE;
  status: USER_TICKET_STATUS;
  closedAt?: string;
  createdAt: string;
  _createdBy?: User;
  _assignedTo?: User;
  _comments?: UserTicketComment[];
  _user?: User;
};

export type UnleashBudget = {
  total: number;
  user: number;
  currency: string;
  category: {
    id: number;
    name: string;
  };
  bonuses: Array<
    Omit<UnleashBudget, 'bonuses' | 'category'> & {
      unleashCategory: UnleashCategory;
      used?: number;
    }
  >;
  used?: number;
};

export type SimplifiedUser = {
  id: number;
  displayName: string;
  avatarUrl: string;
  email?: string;
};

export type ShippingAddress = Definitions['shippingAddress'];

export type CountryState = { code: string; name: string };
export type Country = CountryState & { states: CountryState[] | null };

export type AccountingData = {
  info?: Record<string, any> & { latestUpdates?: number[] };
  notes?: string;
  _paymentType?: PaymentType;
};

export type PaymentType = {
  id: number;
  name: typeof PAYMENT_METHOD[keyof typeof PAYMENT_METHOD];
  _currency?: Currency;
};

export type PaymentViaType = {
  id: string;
  name?: string;
};

export type EmergencyContact = {
  name?: string;
  email?: string;
  countryCallingCode?: string;
  telephone?: string;
};

export type ShirtSize = {
  id: number;
  name: string;
};

export type Role = {
  id: number;
  name: UserRole;
};
export type UserType = {
  id: string;
};

export type Filter<
  T = {
    fast?: string;
    _order?: string;
    page?: number | null;
    pageSize?: number;
    group?: string;
    search?: string;
    season?: string;
    status?: string;
    type?: string;
    showExpired?: boolean;
    accept?: string | null;
    decline?: string | null;
    owner?: string;
    manager?: string;
    csm?: string;
    employmentType?: string;
    managers?: number | string[];
    csms?: number | string[];
    xtcReps?: number | string[];
    countries?: string | string[];
    owners?: string | string[];
    startDate?: number;
    endDate?: number;
    company?: string;
    bcid?: number;
    categoryId?: number;
    subCategoryId?: number;
    collectionId?: string;
    paymentType?: string;
    paymentVia?: number | string;
    notes?: string;
    hasExpired?: string;
  }
> = {
  selected: T;
  filterSelectChange: (field: string) => (value: string | string[]) => void;
  updateFilter: (options?: T) => void;
};

export type SortFilter = [string, SortDirection];

export type Document = {
  id: number;
  title: string;
  description?: string;
  url?: string;
  file?: File;
  _project?: Project;
};

export type SignedDocument = {
  id: number;
  signedAt: Date;
  _document?: Document;
  _user?: User;
};

export type AccountingCode = {
  name?: string;
};

export type TaskBreakdown = {
  taskName: string;
  totalTaskHours: string;
};

export type Assignment = {
  id: number;
  isActive: boolean;
  rate?: number;
  _project?: Project;
  _projectId?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  _user?: User;
  _userId?: number;
  rateUpdated?: boolean;
  hasSignedDocuments?: boolean;
  notes?: string;
  hoursLimit?: number;
  invoiceItem?: InvoiceItem;
  taskBreakdownSummedByCategory?: TaskBreakdown[];
  taskBreakdownItemized?: TaskBreakdownItem[];
  totalHours?: string;
  _history?: AssignmentHistory[];
};

export type AssignmentHistory = {
  id: number;
  addedAt: Date;
  oldValue: string;
  newValue: string;
  description: string;
  _createdByUser: User;
  _assignmentId: number;
  effectiveDate?: string | null;
};

export type AllowedReimbursement = {
  id: number;
  name: string;
};

export type AllowedExpense = {
  id: number;
  name: string;
};

export type ProjectStatusName = typeof STATUS[keyof typeof STATUS];

export type RewardType = {
  id: string;
  name: string;
};

export interface UnlockedBountyCollection extends UnlockedBounties {
  addedAt: string;
  comment?: string;
  _bountyCollectionId: number;
}

export type BountiesCollection = {
  id: number;
  order: number;
  featured: boolean;
  passRequired?: boolean;
  isProgressive?: boolean;
  icon?: File | null;
  expireDate?: Date;
  isActive: boolean;
  name: string;
  rewardValue?: number | null;
  vaultItemLink?: string | null;
  vaultItemName?: string | null;
  _rewardType?: RewardType;
  _bounties?: { id: number; title: string }[];
  _userGroups?: { id: number }[];
  _unlockedBountiesCollection?: UnlockedBountyCollection[];
  season?: number;
};

export type Project = {
  id: number;
  name?: string;
  contractCode?: number;
  _admin?: User;
  _csms?: CsmAssignment[];
  _status?: Status;
  _accountingCode?: AccountingCode;
  _assignments?: Assignment[];
  _allowedReimbursements?: AllowedReimbursement[];
  _allowedExpenses?: AllowedExpense[];
  _department?: Department;
  _company?: CompanyModel;
  _companyId?: number;
  activeAssignments?: Assignment[] | number;
  lastInvoiceSubmittedAt?: Date | string;
  _documents?: Document[];
  harvestId?: string | null;
  productiveId?: string | null;
  canSubmitHoursInvoices?: boolean;
  type?: ProjectType;
  excludeFromNotifications?: boolean;
  allowsNonBillable?: boolean;
};

export type Department = {
  id: number;
  name: string;
};

export type CsmAssignment = {
  id: number;
  _projectId: number;
  _userId: number;
  isActive: boolean;
  addedAt?: Date;
  displayName?: string;
  avatarUrl?: string;
};

export type Status = {
  id: number;
  name: ProjectStatusName;
};

export type ProjectType = {
  id: keyof typeof PROJECT_TYPES;
};

export type ProjectTypeValues = ProjectType[keyof ProjectType];

export type File = {
  location: string;
  bucket: string;
  key: string;
  name: string;
  originalName: string;
};

export type ToastMessage = {
  id?: string;
  text: string | Record<string, any>;
  type?: 'success' | 'warning' | 'info' | 'error';
  autoClose?: number;
};

export type Answer = {
  id: number;
  value: string | number;
  _question?: Question;
  _invoice?: Invoice;
  notes?: string | undefined;
};

export type QuestionItem = {
  text: string;
  order: number;
  id?: number;
};

export type Question = {
  id?: string;
  title: string;
  text?: string;
  isRequired?: boolean;
  order?: number;
  _userGroups?: UserApiModel[];
  _userGroupId?: number;
  _questionItems?: QuestionItem[];
  _type?: any;
  isActive?: boolean;
  addedAt?: Date;
  _createdBy?: User;
  hasAnswers?: boolean;
  allowMultipleItemsSelection?: boolean;
};

export type QuestionType = {
  id?: string;
  name?: string;
};

export type QuestionTypeDefinition = 'mood' | 'text' | 'list';

export type UserNote = {
  id: number;
  description: string;
  isOpen?: boolean;
  closedAt?: Date | string | null;
  files?: File[];
  _type?: { id: string };
  _owner?: Partial<UserApiModel>;
  _user?: Partial<UserApiModel>;
  _assignedTo?: Partial<UserApiModel>;
  _closedBy?: Partial<UserApiModel>;
  createdAt?: Date | string | null;
};

export type Team = {
  id: number;
  name: string;
  isActive?: boolean;
  slackWebhook?: string | null;
  _slackChannelId?: string | null;
  _slackChannel?: { id: string; name: string };
  icon?: File | null;
  background?: File | null;
  description?: string | null;
  youtubeVideoId?: string | null;
  _teamLeads?: { _user: UserApiModel }[];
  _teamFlagBearers?: { _user: UserApiModel }[];
  _members?: UserApiModel[];
  _teamExclusives?: { id?: number; image: File }[];
};

export type VaultCollection = {
  id: number;
  isActive?: boolean;
  name: string;
  icon?: File;
  order: number;
  _vaultItems?: { id: number; name: string }[];
};

export type EmploymentType = {
  id: string;
  name?: string;
};

export type BountyListApiModel = GetArrayItem<
  Definitions,
  ['getUsersUserIdBountyListResponse', 'data']
>;
export type BountiesApiModel = GetArrayItem<Definitions, ['_bounties']>;
export type UnlockedBountiesApiModel = GetArrayItem<Definitions, ['_unlockedBounties']>;
export type GetBountiesCollectionItem = GetArrayItem<
  Definitions,
  ['getBountiesCollectionsResponse', 'data']
>;
export type GetUserBountiesCollectionItem = GetArrayItem<
  definitions,
  ['getUsersUserIdBountiesCollectionsResponse', 'data']
>;

export type BountyListsModel = {
  id: number;
  name: string;
  _owner?: UserApiModel;
};

export type BountySeason = {
  season: number;
};

type SlackChannel = {
  id: number;
  name: string;
};

export type DragAndDrop = {
  isDragging: boolean;
  destination: {
    index: number;
    droppableId: string;
  };
  source: {
    index: number;
  };
};

export type UnlockedBounties = {
  id: number;
  _grantedTo: {
    id: number;
    displayName: string;
    avatarUrl?: string;
  };
};

export type UserGroupMember = {
  addedAt: string;
  _userGroupId: number;
  _userId: number;
  _user: SimplifiedUser;
};

export type UserGroupMemberListItem = {
  id: number;
  addedAt: string;
  displayName: string;
  avatarUrl: string;
};

export type UserGroup = {
  id: number;
  name: string;
  _slackChannelId: string | null;
  _adminId?: number;
  _admin: SimplifiedUser;
  _members?: SimplifiedUser[];
  _questions?: Question[];
};

export type UnleashCategory = {
  id?: number;
  name?: string;
  isBonusCategory?: boolean;
  limit?: number | null;
};

export type CompanyLogoValues = {
  availableValues?: string[];
  value?: string;
};

export enum RATE_UPDATE_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
}

export enum RATE_UPDATE_REASON {
  ANNUAL_PERFORMANCE = 'ANNUAL_PERFORMANCE',
  NEW_PROJECT = 'NEW_PROJECT',
  COUNTER_OFFER = 'COUNTER_OFFER',
  OTHER = 'OTHER',
}

export interface UnleashSetting {
  hasMonthlyTotalLimit: boolean;
  monthlyTotalLimit: string;
}

export interface VaultItemPurchase {
  id: number;
  _buyer: Buyer;
}

export interface Buyer {
  id: number;
  displayName: string;
  avatarUrl: string;
}

export interface Type {
  id: InvoiceItemTypes;
}

export type UnleashCategoryModel = {
  description: string;
  id: number;
  image?: File;
  limit?: number;
  name: string;
  order: number;
  isBonusCategory: boolean;
  isActive: boolean;
};

export type InvoiceItemTypes = typeof INVOICE_ITEM_TYPES[keyof typeof INVOICE_ITEM_TYPES];

export type InvoiceApiModel = GetArrayItem<Definitions, ['getInvoicesResponse', 'data']>;
export type JobOpeningApiModel = GetArrayItem<Definitions, ['getJobOpeningsResponse', 'data']>;
export type UserApiModel = GetArrayItem<Definitions, ['getUsersResponse', 'data']>;
export type BountyApiModel = GetArrayItem<Definitions, ['getBountiesResponse', 'data']>;
export type LegendApiModel = GetArrayItem<Definitions, ['getLegendsResponse', 'data']>;
export type LegacyApiModel = GetArrayItem<Definitions, ['getLegacyResponse', 'data']>;

export type Maybe<T> = T | null | undefined;

export type Get<Obj extends Record<string, any> | null | undefined, Keys extends (keyof any)[]> = {
  0: Obj;
  1: _Get<Obj, _Head<Keys>> extends infer R
    ? R extends Record<string, any>
      ? Get<R, _Tail<Keys>>
      : R
    : never;
}[Keys['length'] extends 0 ? 0 : 1];

export type GetArrayItem<
  Obj extends Record<string, any> | null | undefined,
  Keys extends (keyof any)[]
> = Get<Obj, Keys> extends infer R ? (R extends any[] ? NonNullable<R[number]> : never) : never;

export type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K;
} extends { [_ in keyof T]: infer U }
  ? U
  : never;

/**
 * Helper types
 */
export type _Get<
  Obj extends Record<string, any> | null | undefined,
  Keys extends keyof any
> = Keys extends keyof NonNullable<Obj> ? NonNullable<Obj>[Keys] : undefined | null;

type _Head<Arr extends any[]> = Arr['length'] extends 0 ? never : Arr[0];

type _Tail<Arr extends any[]> = ((...t: Arr) => any) extends (head: any, ...tail: infer Tail) => any
  ? Tail
  : never;

export interface ModalProps {
  closeModal: () => void;
  opened: boolean;
}

export type VaultItemTypes = typeof VAULT_ITEM_TYPES[keyof typeof VAULT_ITEM_TYPES];

export type VaultItemGoals = typeof VAULT_ITEM_GOALS[keyof typeof VAULT_ITEM_GOALS];
export type RarityTypes = typeof RARITY_TYPES[keyof typeof RARITY_TYPES];

export type BountiesShowOptions = 'allUnhidden' | 'collected' | 'uncollected' | 'hidden';

export type BountiesTypeOptions = 'all' | 'season' | 'user' | 'privateBounty' | 'favorite';

export type TaskBreakdownItem = {
  taskId?: number;
  taskName: string;
  taskHours: string;
};

export type HarvestAPIDataType = {
  projectId: number;
  totalHours: string;
  taskBreakdownItemized?: TaskBreakdownItem[];
  taskBreakdownSummedByCategory?: TaskBreakdown[];
  projectName: string;
};

export type ProductiveAPIDataType = {
  projectId: number;
  totalHours: string;
  taskBreakdownItemized?: TaskBreakdownItem[];
  taskBreakdownSummedByCategory?: TaskBreakdown[];
  projectName: string;
};

export type DateRange = {
  id?: string;
  start: moment.Moment | string;
  end: moment.Moment | string;
};

export type DateRangeAlt = {
  id?: string;
  start: Date | string;
  end: Date | string;
};

export type BetOption = GetArrayItem<Definitions, ['options', 'data']>;
export type BetCoinOption = GetArrayItem<Definitions, ['coinOptions', 'data']>;
export type Bet = GetArrayItem<Definitions, ['getBetsResponse', 'data']>;

export type ToggleablePages = 'houses' | 'legends';
export type DisabledMenuItems = { [key in ToggleablePages]?: boolean };

export enum IMG_LOADING {
  LAZY = 'lazy',
  EAGER = 'eager',
}

export interface ImageOptions {
  loading?: IMG_LOADING.LAZY | IMG_LOADING.EAGER;
  width: number;
  height: number;
}

export type SelectionState = 'selected' | 'default' | 'disabled';

export type StepState = 'answered' | 'pending' | 'optional';
export type ActiveState = 'inactive' | 'pending' | 'active' | 'loading';
export type ButtonActiveState = ActiveState | 'disabled';

export interface StepActions {
  state: ActiveState;
  activeHandler?: () => void;
  inactiveHandler?: () => void;
}

export type BountyCategory = {
  id: number;
  name: string;
  active?: boolean;
  _subCategories?: BountySubCategory[];
  _addedBy?: User;
  _addedById?: number;
};

export type BountySubCategory = {
  id: number;
  name: string;
  _bountyCategoryId: number;
  active?: boolean;
};

export type Pageable<T> = {
  data: Array<T>;
  page: number;
  pageSize: number;
  total: number;
  pageCount: number;
};

export type HeaderType = {
  children: React.ReactNode;
  sortColumnName?: string;
  sortDirection?: SortDirection;
  sortActive?: boolean;
};

export interface SortHandler<T> {
  match: (a?: T, b?: T) => boolean;
  compare: (a: T, b: T) => number;
}
