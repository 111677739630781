import { memo } from 'react';

import Row from 'components/Row';
import { parseNumber } from 'helpers/parseHelpers';
import { InvoiceItem, User, Invoice, Currency } from 'types/types';
import { formatCurrency } from 'utils/helpers';
import { isNewProjectExpensesEnabled } from 'utils/netsuiteIntegration';

import styles from './ReimbursementItem.module.scss';

type ReimbursementItemProps = {
  item: InvoiceItem;
  invoice: Invoice;
  users: User[];
  referralInvoiceCategory: Currency;
  unleashPlusInvoiceCategory: Currency;
};

const ReimbursementItem = (props: ReimbursementItemProps) => {
  const { item, users, invoice, referralInvoiceCategory, unleashPlusInvoiceCategory } = props;
  const isUnleashPlusItem = (item: InvoiceItem) => {
    const itemCategoryId = isNewProjectExpensesEnabled()
      ? item._expenseCategory?.id
      : item._category?.id;
    return itemCategoryId === unleashPlusInvoiceCategory.id;
  };
  const getReferralName = (id: number) => {
    const referral = users.find((u) => u.id === Number(id));
    return referral?.displayName || id;
  };
  const isReferralItem = (item: InvoiceItem) => {
    const itemCategoryId = isNewProjectExpensesEnabled()
      ? item._expenseCategory?.id
      : item._category?.id;
    return itemCategoryId === referralInvoiceCategory.id;
  };

  return (
    <div key={item.id}>
      <Row noPadding className={styles.SummaryRow}>
        <span className={styles.SummaryLabel}>Submission type:</span>
        <span className={styles.SummaryValue}>
          {isNewProjectExpensesEnabled() ? item._expenseCategory?.name : item._category?.name}
        </span>
      </Row>
      {isUnleashPlusItem(item) && (
        <Row noPadding className={styles.SummaryRow}>
          <span className={styles.SummaryLabel}>Unleash+ Category:</span>
          <span className={styles.SummaryValue}>{item._unleashCategory?.name}</span>
        </Row>
      )}
      <Row noPadding className={styles.SummaryRow}>
        <span className={styles.SummaryLabel}>Project:</span>
        <span className={styles.SummaryValue}>{item._project?.name}</span>
      </Row>
      <Row noPadding className={styles.SummaryRow}>
        <span className={styles.SummaryLabel}>Unit Price:</span>
        <span className={styles.SummaryCalculation}>
          {item.quantity}x({formatCurrency(item.rate, invoice._currency?.name)}/hr)
          <div className={styles.SummaryCalculationTotal}>
            Total: {parseNumber(item.quantity * item.rate)} {invoice._currency?.name}
          </div>
        </span>
      </Row>
      <Row noPadding className={styles.SummaryRow}>
        <span className={styles.SummaryLabel}>Quantity:</span>
        <span className={styles.SummaryValue}>{item.quantity}</span>
      </Row>
      {isReferralItem(item) && (
        <Row noPadding className={styles.SummaryRow}>
          <span className={styles.SummaryLabel}>X-Teamer:</span>
          <span className={styles.SummaryNotes}>{getReferralName(+(item.name || ''))}</span>
        </Row>
      )}
      <Row noPadding className={styles.SummaryRow}>
        <span className={styles.SummaryLabel}>Notes:</span>
        <span className={styles.SummaryNotes}>{item.notes}</span>
      </Row>
    </div>
  );
};

export type { ReimbursementItemProps };
export default memo(ReimbursementItem);
