import { memo } from 'react';

import { ReferralCard } from 'design-system';

import { JobOpeningApiModel } from 'types/types';

import styles from './JobOpeningList.module.scss';

export interface JobOpeningListProps {
  jobOpenings: JobOpeningApiModel[];
  onJobClick: (job: JobOpeningApiModel) => void;
}

export const JobOpeningList = memo<JobOpeningListProps>(({ jobOpenings, onJobClick }) => (
  <div data-testid="TestId__JOBOPENING__WRAPPER" className={styles.ReferralCardsContainer}>
    {jobOpenings.map((job: JobOpeningApiModel) => (
      <ReferralCard
        description={job.publicDescription || ''}
        skills={job.skills?.data?.map((skill) => skill.name || '') || []}
        title={job.title || ''}
        key={job.id}
        onCardClick={() => onJobClick(job)}
      />
    ))}
  </div>
));
