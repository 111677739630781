import { memo, useState, DragEvent, useMemo, useCallback, useEffect, ChangeEvent } from 'react';

import cs from 'classnames';
import {
  Icon,
  Input,
  Paragraph,
  useIsMobile,
  FilesUploader,
  EditableParagraph,
  Select,
  OverUnleashConfirmModal,
  Modal,
  Button,
  Heading,
} from 'design-system';
import { isNil } from 'lodash';

import { SelectOption, SetSelectOption } from 'design-system/Atoms/Select/Select.helper';
import { Assignment, CurrencyName, File, InvoiceItem, UnleashCategory } from 'types/types';
import {
  useGetUnleashCategories,
  useGetUserUnleashInvoiceItemsOfCurrentMonthByCategoryId,
} from 'utils/apiQueryHooks';
import { verifyIfHasExceededUnleashCategory } from 'utils/helpers';

import { QuantityAndPriceRow } from './QuantityAndPriceRow';
import {
  formatInitialValues,
  formatInputPayload,
  getFullPayload,
  ReimbursementCardForm,
  areRequiredFieldsFilled,
} from './utils';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './ReimbursementCard.module.scss';

export type ReimbursementCardType = {
  assignments: Assignment[];
  isModalMode?: boolean;
  isSaveButtonDisabled?: boolean;
  reimbursement: Partial<InvoiceItem>;
  updateReimbursement: (invoiceItemId: number, invoiceItemData: Partial<InvoiceItem>) => void;
  currency: CurrencyName;
  isDarkModeSelected?: boolean;
  onSubmitHandler?: (invoiceItem: InvoiceItem) => void;
  onCloseModal?: () => void;
  referrals?: SelectOption[];
  onDelete?: () => void;
  onUploadFile?: (invoiceItemId: number, formData: FormData) => Promise<File[]>;
  onRemoveFile?: (invoiceItemId: number, updatedFiles: File[]) => Promise<void>;
  // Validation props:
  availableUnleashBudget?: number;
  exceededCategory?: UnleashCategory | null;
  onExceedConfirm?: (confirmed: boolean) => void;
  setExceededCategory?: (category: UnleashCategory | null) => void;
  setUnleashBudgetError?: (error: boolean) => void;
};

export interface ReimbursementErrorsType {
  isOverUnleashLimit: boolean;
}

type CategoryOption = SelectOption & { limit: number };

function getCategoryLimit(categories: CategoryOption[] = [], categoryValue?: string | number) {
  return categories.find((c) => c.value === categoryValue)?.limit;
}

export const ReimbursementCard = memo<ReimbursementCardType>((props) => {
  const {
    reimbursement,
    setUnleashBudgetError,
    onExceedConfirm,
    isModalMode,
    onCloseModal = () => null,
    onSubmitHandler,
    isSaveButtonDisabled,
  } = props;
  const [formValues, setFormValues] = useState<ReimbursementCardForm>(
    formatInitialValues(reimbursement, props.referrals),
  );
  const [reimbursementTypes, setReimbursementTypes] = useState<SelectOption[]>([]);
  const [overUnleashLimit, setOverUnleashLimit] = useState(false);
  const isMobile = useIsMobile();
  const { resolvedData } = useGetUnleashCategories(true, { suspense: false });
  const unleashCategories = useMemo(() => {
    return resolvedData?.data?.map((category) => ({
      value: category.id,
      label: category.name,
      limit: category.limit,
    })) as CategoryOption[];
  }, [resolvedData?.data]);

  const isApproved = !!reimbursement?._approvedBy?.id;
  const isPaid = !!reimbursement?._paidBy?.id;
  const isEditable = !(isApproved || isPaid);
  const reimbursementId = Number(reimbursement?.id) || -1;
  const selectedTypeLabel = (formValues?.reimbursementType as SelectOption)?.label?.toLowerCase();
  const selectedProjectId = Number((formValues?.project as SelectOption)?.value);
  const quantity = Number(formValues?.quantity);
  const unitPrice = Number(formValues?.unitPrice);
  const fileNames = formValues?.files?.map((file) => file?.originalName) || [];
  const isReferralType = selectedTypeLabel === 'referral';
  const isUnleashType = selectedTypeLabel === 'unleash+';
  const currentMonthUnleashSelectedCategoryInvoiceItems = useGetUserUnleashInvoiceItemsOfCurrentMonthByCategoryId(
    formValues?.unleashCategory?.value as number,
    { suspense: false },
  );

  const verifyUnleashBudget = useCallback(() => {
    const invoiceItem = {
      ...getFullPayload(formValues),
      _unleashCategory: {
        limit: getCategoryLimit(unleashCategories, formValues?.unleashCategory?.value),
      },
    };
    const hasExceededUnleashCategory = verifyIfHasExceededUnleashCategory(
      currentMonthUnleashSelectedCategoryInvoiceItems,
      invoiceItem,
    );
    const hasUnleashBudgetError =
      isUnleashType && hasExceededUnleashCategory && !formValues.overTimeApprover;
    setUnleashBudgetError?.(hasUnleashBudgetError);
    setOverUnleashLimit(hasUnleashBudgetError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMonthUnleashSelectedCategoryInvoiceItems?.length,
    formValues.quantity,
    formValues.unitPrice,
    unleashCategories,
    formValues?.unleashCategory?.value,
    formValues.unleashCategory,
    formValues.overTimeApprover,
    isUnleashType,
    setUnleashBudgetError,
  ]);

  const projectOptions = useMemo(
    () =>
      props.assignments?.map((assignment) => ({
        value: String(assignment._project?.id),
        label: String(assignment._project?.name),
      })),
    [props.assignments],
  );

  const handleInputChange = useCallback(
    (fieldName: keyof ReimbursementCardForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const parsedValue = ['quantity', 'unitPrice'].includes(fieldName) ? Number(value) : value;
      setFormValues((state) => ({ ...state, [fieldName]: parsedValue }));
    },
    [],
  );

  const handleSave = useCallback(
    (save: () => void, values: ReimbursementCardForm) => {
      const { unitPrice, quantity } = values;
      const totalUnleashValue = (unitPrice ?? 0) * (quantity ?? 0);
      const selectedUnleashCategoryLimit = getCategoryLimit(
        unleashCategories,
        values?.unleashCategory?.value,
      );
      const overUnleash =
        isUnleashType &&
        !isNil(selectedUnleashCategoryLimit) &&
        totalUnleashValue > selectedUnleashCategoryLimit &&
        !formValues.overTimeApprover;
      onExceedConfirm?.(false);
      if (!overUnleash) {
        save();
      }
      setOverUnleashLimit(overUnleash);
    },
    [isUnleashType, formValues.overTimeApprover, unleashCategories, onExceedConfirm],
  );

  const handleInputBlur = useCallback(() => {
    const saveData = () => {
      props.updateReimbursement(reimbursementId, getFullPayload(formValues));
    };
    handleSave(saveData, formValues);
  }, [formValues, handleSave, props, reimbursementId]);

  const handleSelectChange = useCallback(
    (fieldName: keyof ReimbursementCardForm) => (selectedOption: SetSelectOption) => {
      const values = { ...formValues, [fieldName]: selectedOption };
      setFormValues(values);
      const saveData = () => {
        props.updateReimbursement(reimbursementId, getFullPayload(values));
      };
      handleSave(saveData, values);
    },
    [handleSave, props, reimbursementId, formValues],
  );

  const uploadFiles = async (fileList: FileList) => {
    const files = Array.from(fileList || []);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    const newFiles = (await props.onUploadFile?.(Number(reimbursement?.id), formData)) ?? [];
    setFormValues((state) => ({ ...state, files: [...(state.files || []), ...newFiles] }));
  };

  const onFileDrop = (event: DragEvent<HTMLDivElement>) => {
    const fileList = event.dataTransfer?.files;
    uploadFiles(fileList);
  };

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event?.target?.files as FileList;
    uploadFiles(fileList);
  };

  const removeFile = (fileIndex: number) => {
    props.onExceedConfirm?.(false);
    const updatedFiles = reimbursement?.files?.filter((_, index) => index !== fileIndex) as File[];
    props.onRemoveFile?.(Number(reimbursement?.id), updatedFiles);
  };

  const getReimbursementTypes = useCallback(() => {
    const project = props.assignments?.filter(
      (assignment) => assignment._project?.id === selectedProjectId,
    )?.[0]?._project;
    return project?._allowedReimbursements
      ?.map((reimbursement) => ({
        value: String(reimbursement.id),
        label: String(reimbursement.name),
      }))
      ?.sort((reimbursement) => (reimbursement?.label === 'Unleash+' ? -1 : 1)) as SelectOption[];
  }, [props.assignments, selectedProjectId]);

  const onOverUnleashConfirm = useCallback(
    async (overTimeApprover: string) => {
      setOverUnleashLimit(false);
      props.onExceedConfirm?.(false);
      props.setUnleashBudgetError?.(false);
      props.setExceededCategory?.(null);
      setFormValues((state) => ({ ...state, overTimeApprover }));
      await props.updateReimbursement(Number(reimbursement.id), {
        ...getFullPayload(formValues),
        overTimeApprover,
      });
    },
    [props, reimbursement.id, formValues],
  );

  const cancelExceedConfirmHandler = useCallback(async () => {
    setOverUnleashLimit(false);
    props.onExceedConfirm?.(true);
    props.setExceededCategory?.(null);
  }, [props]);

  useEffect(() => {
    if (selectedProjectId) {
      setReimbursementTypes(getReimbursementTypes());
    }
  }, [getReimbursementTypes, selectedProjectId]);

  useEffect(() => {
    if (reimbursement?.id) {
      setFormValues(formatInitialValues(reimbursement, props.referrals));
    }
  }, [reimbursement, props.referrals]);

  useEffect(() => {
    if (isReferralType && !formValues?.unitPrice) {
      setFormValues((state) => ({ ...state, unitPrice: 500 }));
      props.updateReimbursement(
        reimbursementId,
        formatInputPayload('unitPrice', { ...formValues, unitPrice: 500 }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.unitPrice, formValues?.unleashCategory, isReferralType]);

  useEffect(() => {
    verifyUnleashBudget();
  }, [verifyUnleashBudget]);

  const onOverTimeApproverChange = useCallback(() => {
    setOverUnleashLimit(true);
  }, []);

  const isValid = !overUnleashLimit && !isSaveButtonDisabled && areRequiredFieldsFilled(formValues);

  const onSubmit = useCallback(() => {
    onSubmitHandler?.(getFullPayload(formValues) as InvoiceItem);
  }, [onSubmitHandler, formValues]);

  function renderContent() {
    return (
      <>
        <div>
          <div className={cs(styles.DoubleRow, styles.InputContainer)}>
            <Select
              label="Project name"
              labelClassName={styles.SelectLabel}
              placeholder="Select project"
              onChange={handleSelectChange('project')}
              options={projectOptions}
              value={formValues?.project}
            />
            <Select
              label="Submission type"
              placeholder="Select type"
              labelClassName={styles.SelectLabel}
              onChange={handleSelectChange('reimbursementType')}
              options={reimbursementTypes}
              value={formValues?.reimbursementType}
            />
          </div>
          <QuantityAndPriceRow
            onQuantityChange={handleInputChange('quantity')}
            onQuantityBlur={handleInputBlur}
            onPriceChange={handleInputChange('unitPrice')}
            onPriceBlur={handleInputBlur}
            quantity={quantity}
            unitPrice={unitPrice}
          />
          {isUnleashType && (
            <div className={styles.Row}>
              <Select
                label="Unleash+ Category"
                labelClassName={styles.UnleashCategoryLabel}
                onChange={handleSelectChange('unleashCategory')}
                options={unleashCategories}
                placeholder="Select category"
                value={formValues?.unleashCategory}
              />
            </div>
          )}
          {!!props.referrals?.length && isReferralType && (
            <div className={styles.Row}>
              <Select
                label="Referral name"
                onChange={handleSelectChange('referral')}
                options={props.referrals || []}
                placeholder="Enter X-Teamer name"
                value={formValues?.referral}
              />
            </div>
          )}
          <div className={styles.Row}>
            <Input
              state={'inactive'}
              label={'Notes'}
              placeholder={'Enter notes'}
              value={formValues?.notes}
              layout={'fluid'}
              mode={'writable'}
              onChange={handleInputChange('notes')}
              onBlur={handleInputBlur}
              inputTestId="notes"
            />
          </div>
          <FilesUploader
            isEditable={isEditable}
            uploadedFiles={fileNames}
            onFileDrop={onFileDrop}
            onFileInputChange={onFileInputChange}
            removeFile={removeFile}
            dropSectionTitle={'Receipt'}
            dropSectionClassName={cs({ [styles.DropSectionDarkTheme]: props.isDarkModeSelected })}
            uploadedSectionTitle={'Files'}
          />
          {formValues.overTimeApprover && (
            <EditableParagraph onEdit={onOverTimeApproverChange}>
              <span>
                Authorized by <b>{formValues.overTimeApprover}</b>
              </span>
            </EditableParagraph>
          )}
        </div>
        {isEditable && (
          <>
            {!isMobile && !isModalMode ? (
              <Icon
                name={'close-big'}
                onClick={props.onDelete}
                data-testid="delete-reimbursement-btn"
              />
            ) : null}
            {isMobile && !isModalMode && (
              <div
                className={styles.FlexRow}
                onClick={props.onDelete}
                data-testid="mobile-delete-reimbursement-btn"
                onKeyPress={props.onDelete}
                role={'button'}
                tabIndex={-1}
              >
                <div className={styles.MobileDeleteContainer}>
                  <Paragraph className={styles.MobileDeleteText} variant={'ui-bold'}>
                    Remove reimbursement
                  </Paragraph>
                  <div className={styles.MobileDeleteIconContainer}>
                    <Icon name={'trash-outline'} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {overUnleashLimit && (
          <OverUnleashConfirmModal
            data-testid="overUnleashModal"
            approvedBy={formValues.overTimeApprover as string}
            availableBudget={props.availableUnleashBudget}
            categoryName={formValues?.unleashCategory?.label}
            categoryLimit={getCategoryLimit(unleashCategories, formValues?.unleashCategory?.value)}
            onCancel={cancelExceedConfirmHandler}
            onConfirm={(name: string) => onOverUnleashConfirm(name)}
          />
        )}
      </>
    );
  }
  return isModalMode ? (
    <Modal modalClassName={styles.ModalContainer} onClose={onCloseModal}>
      <Heading
        data-testid="TestId__REIMBURSEMENTCARD_MODAL_TITLE"
        level="h5"
        className={styles.Heading}
      >
        Add reimbursement
      </Heading>
      {renderContent()}
      <div className={styles.ButtonContainer}>
        <Button
          variant="primary"
          layout="fluid"
          iconPosition="start"
          data-testid="TestId__REIMBURSEMENTCARD_SAVE_BTN"
          as="button"
          type="button"
          className={styles.SaveButton}
          onClick={onSubmit}
          disabled={!isValid}
        >
          Save
        </Button>
      </div>
    </Modal>
  ) : (
    <div className={styles.Container}>{renderContent()}</div>
  );
});
