import { Divider, ReferralCard, Button, useIsMobile, Modal } from 'design-system';
import Markdown from 'markdown-to-jsx';

import { JobOpeningApiModel } from 'types/types';

import styles from './OpenJobDetails.module.scss';

export interface OpenJobDetailsProps {
  job: JobOpeningApiModel;
  onCloseJobDetails: () => void;
  onClickReferAFriend: () => void;
  onClickCopyLinkToReferAFriend: () => void;
}

export const formatDescription = (description: string | undefined) =>
  description ? <Markdown>{description}</Markdown> : null;

const renderOpenJobDetails = (props: OpenJobDetailsProps, isMobile: boolean) => {
  const { job, onCloseJobDetails, onClickReferAFriend, onClickCopyLinkToReferAFriend } = props;

  return (
    <div data-testid="TestId__OPENJOBDETAILS__WRAPPER" className={styles.OpenJobDetails}>
      <ReferralCard
        description={isMobile ? '' : job.publicDescription || ''}
        onCardClick={() => undefined}
        skills={job.skills?.data?.map((skill) => skill.name || '') || []}
        title={job.title || ''}
        key={job.id}
        closable={!isMobile}
        onClose={onCloseJobDetails}
        className={styles.ReferralCard}
      >
        {!isMobile && (
          <>
            <div className={styles.ButtonContainer}>
              <Button
                variant="primary"
                layout="autolayout"
                as="button"
                data-testid="TestId__OPENJOBDETAILS__REFERAFRIEND"
                onClick={onClickReferAFriend}
              >
                Refer a friend
              </Button>
              <Button
                variant="secondary"
                layout="autolayout"
                as="button"
                data-testid="TestId__OPENJOBDETAILS__COPYLINK"
                onClick={onClickCopyLinkToReferAFriend}
              >
                Copy Share Link
              </Button>
            </div>
            <Divider />
          </>
        )}
        <div data-testid="TestId__OPENJOBDETAILS__DESCRIPTION" className={styles.Description}>
          {formatDescription(job.publicDescription)}
        </div>
      </ReferralCard>
    </div>
  );
};

export const OpenJobDetails = (props: OpenJobDetailsProps) => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <Modal
          contentClassName={styles.ModalContent}
          onClose={props.onCloseJobDetails}
          onSubmit={props.onClickReferAFriend}
          onSecondaryButtonClick={props.onClickCopyLinkToReferAFriend}
          primaryButtonContent="Refer a friend"
          secondaryButtonContent="Copy Share Link"
          footerClassName={styles.ModalFooter}
        >
          {renderOpenJobDetails(props, isMobile)}
        </Modal>
      ) : (
        renderOpenJobDetails(props, isMobile)
      )}
    </>
  );
};
