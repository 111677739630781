import { memo, useCallback, Suspense } from 'react';

import { PaginatedQueryResult, QueryConfig } from 'react-query';

import { KnownKeys } from 'types/types';
import { XhqFetchOptions } from 'utils/xhqFetcher';

import Select, { NONE_OPTION } from '../Select';

export type OptionFields = { id?: number | string; name?: string };

type ApiQuerySelectOwnProps<T extends OptionFields, IsMulti> = {
  mapLabel?: (id: T['name'] | T['id'], option: T) => string;
  sortOptions?: boolean;
  useGetQuery: (
    opt1?: XhqFetchOptions | true,
    opt2?: QueryConfig<any>,
  ) => PaginatedQueryResult<{ data?: T[] }>;
  filterOptions?: (option: T, index: number, array: T[]) => boolean;
  mapOptions?: (option: T, index: number, array: T[]) => T;
  processOptions?: (options: T[]) => T[];
  onChange: (
    value: IsMulti extends true ? undefined : T,
    selectedOption: IsMulti extends true ? SelectedOption<T>[] : SelectedOption<T>,
  ) => void;
  /**
   * @description Use `isMulti={true as const}` for better typesafety
   */
  isMulti?: IsMulti;
  value?: IsMulti extends true ? number[] | string[] : string | number | null;
  placeholder?: string;
  name?: string;
  mixedId?: boolean;
  isSearchable?: boolean;
  closeMenuOnSelect?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isFilter?: boolean;
  width?: string | number;
  allowNone?: boolean;
  queryOptions?: QueryConfig<any>;
};

type SelectProps = Partial<
  Pick<
    Parameters<typeof Select>[0],
    Exclude<KnownKeys<Parameters<typeof Select>[0]>, keyof ApiQuerySelectOwnProps<any, any>>
  >
> & { isClearable?: boolean; inputId?: string };

export type ApiQueryProps<T extends OptionFields, IsMulti> = SelectProps &
  ApiQuerySelectOwnProps<T, IsMulti>;

export type SelectedOption<T extends OptionFields> = {
  label: string;
  value: T['id'];
  option: T;
};

const ApiQuerySelectInner = <T extends OptionFields, IsMulti>({
  filterOptions,
  mapOptions,
  processOptions,
  onChange,
  useGetQuery,
  isMulti,
  mixedId,
  value,
  queryOptions,
  ...selectProps
}: ApiQueryProps<T, IsMulti>) => {
  const { resolvedData } = useGetQuery({
    query: queryOptions,
  });
  let items = resolvedData?.data;

  const handleChange = useCallback(
    (id: IsMulti extends true ? '' : T['id'], selectedOption) => {
      const mixedIdCondition = mixedId && !Array.isArray(selectedOption);
      const option = items?.find((s) =>
        mixedIdCondition ? s.id === id && s.name === selectedOption.label : s.id === id,
      ) as IsMulti extends true ? undefined : T;
      onChange(
        (selectProps?.allowNone && selectedOption.label === NONE_OPTION.label
          ? NONE_OPTION
          : option) as IsMulti extends true ? undefined : T,
        selectedOption,
      );
    },
    [items, mixedId, onChange, selectProps?.allowNone],
  );

  if (!items) {
    return null;
  }

  if (filterOptions) {
    items = items.filter(filterOptions);
  }

  if (mapOptions) {
    items = items.map(mapOptions);
  }

  if (processOptions && typeof processOptions === 'function') {
    items = processOptions(items);
  }

  return (
    <Select
      {...(selectProps as any)}
      value={value || ''}
      isMulti={isMulti}
      onChange={handleChange}
      items={items}
    />
  );
};

// cast type for better typesafety
const MemoizedApiQuerySelectInner = memo(ApiQuerySelectInner) as typeof ApiQuerySelectInner;

export const ApiQuerySelect = memo((props) => (
  <Suspense fallback={null}>
    <MemoizedApiQuerySelectInner {...props} />
  </Suspense>
)) as typeof ApiQuerySelectInner;
