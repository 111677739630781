import { ReactComponent as DarkLogoIcon } from 'assets/logo-xwp-white.svg';
import { ReactComponent as LogoIcon } from 'assets/logo-xwp.svg';

import { BrandConfig } from './brand-config';

const config: BrandConfig = {
  brandName: 'XWP',
  appName: 'XHQ',
  introText: (
    <>
      You’re one click away from the XWP. <br />
      Login using your xwp.co account.
    </>
  ),
  logo: LogoIcon,
  logoDarkMode: DarkLogoIcon,
  useLogoFromFeatureFlag: false,
  logoSize: {
    width: '8rem',
    height: '3rem',
  },
  flags: {
    companyLogoEnabled: false,
    unleashEnabled: true,
    referEnabled: false,
    vaultEnabled: false,
    bountiesEnabled: false,
    xtuUniverseEnabled: false,
    legendsEnabled: false,
    legacyEnabled: false,
    housesEnabled: false,
    eventsEnabled: false,
    betsEnabled: false,
    seasonsEnabled: false,
    profileEnabled: false,
    outpostEnabled: false,
    privacyPolicyEnabled: false,
    vacationRequestEnabled: false,
    companiesEnabled: true,
    departmentEnabled: false,
    projectExpensesEnabled: false,
    invoiceSurveyEnabled: false,
    invoiceTermsEnabled: false,
    rateUpdateRequests: false,
    giveCoinsEnabled: true,
  },
  startPage: 'lite',
  handbookUrl: 'https://xwp-co.atlassian.net/wiki/spaces/KB/pages/2689368065/Team+Handbook',
  myProfileUrl: 'https://jobs.x-team.com/',
  supportDeskUrl: 'https://x-team.slack.com/archives/CCSHSBUUT',
  faqIntro: '',
  faq: [
    {
      title: 'When do I submit my invoice?',
      content: `Invoice collection happens every 1st of the month. Submit your invoice on the 1st of each month (no later than the 3rd) and you have 2 days to submit your invoice.`,
    },
    {
      title: 'When will I be paid?',
      content: 'We initiate payment transfers by the 7th of the month.',
    },
    {
      title: 'What should I do if I submit my invoice late?',
      content: `Contact finance, it’s very important that invoices for every month are submitted
      for accurate finance reporting.`,
    },
    {
      title: `What should I do if I can't add hours to a project I am currently working on?`,
      content: (
        <>
          This is probably just an oversight. Ask for help in #xwp-tooling-access tagging{' '}
          <a href="slack://user?team=T0257R0RP&id=U02TN85J3JM" title="Open Slack">
            Katie
          </a>{' '}
          and{' '}
          <a href="slack://user?team=T0257R0RP&id=UFMGNQR8D" title="Open Slack">
            Arlen
          </a>
        </>
      ),
    },
    {
      title: 'How do Unleash reimbursements work?',
      content: (
        <>
          A detailed explanation of Unleash can be found{' '}
          <a href="https://xwp-co.atlassian.net/wiki/spaces/KB/pages/2690842655/Unleash">here</a>
        </>
      ),
    },
  ],
  contactContentForInvoices: <>Don't see a project you need listed? Ask&nbsp;</>,
  contactsListForInvoices: [
    {
      name: '@Katie',
      url: 'https://x-team.slack.com/team/U02TN85J3JM',
      additionalText: ' to add it for you in ',
    },
    {
      name: '#xwp-tooling-access',
      url: 'https://x-team.slack.com/archives/C051B7QC6UX',
    },
  ],
  preferences: {
    birthdayPreferenceLabel: 'I am happy for my birthday to be recognized by the XWP Community',
  },
  contractOptions: {
    individual: 'I contracted with XWP as an individual (the contract is in my personal name)',
    company: `I contracted with XWP through a Company (the contract is in my company's name)`,
    isRequired: false,
  },
  isXWP: true,
};

export { config };
