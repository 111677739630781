import { memo, useState } from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Text from 'components/Text';
import Button from 'components/buttons/Button';
import Select from 'components/inputs/Select';
import { useGetJobOpenings } from 'utils/apiQueryHooks';

import styles from './Referrals.module.scss';

export const Referrals = memo(() => {
  const history = useHistory();
  const [jobOpeningId, setJobOpeningId] = useState<string>('');

  const { resolvedData: jobOpenings } = useGetJobOpenings();
  const jobSelectOptions =
    jobOpenings?.data.map(({ title, id }) => ({
      label: title,
      value: id,
    })) ?? [];

  const routeRefer = (allJobs: boolean) => {
    if (allJobs || !jobOpeningId) {
      history.push(`/refer`);
    } else {
      jobOpeningId && history.push(`/refer?job=${jobOpeningId}`);
    }
  };

  const JobOpeningButtonClasses = classNames(styles.ActionButton, styles.JobOpeningsButton);

  return (
    <div className={styles.Referrals}>
      <Text className={styles.EventsTitle} type="uiBold">
        Referrals
      </Text>
      <Text className={styles.ReferralsText}>
        Refer other developers to X-Team and earn{' '}
        <span role="img" aria-label="Emoji representing a bag of money">
          💰
        </span>
        <strong>$500</strong> if they get hired
      </Text>
      <div>
        <Select
          isFilter
          isClearable
          isFullWidth
          placeholder="Choose job"
          onChange={(target: string) => {
            setJobOpeningId(target);
          }}
          className={styles.ReferralsDropdown}
          options={jobSelectOptions}
        />
        <Button
          isFullWidth
          isFormBtn
          onClick={() => routeRefer(false)}
          className={styles.ActionButton}
        >
          Refer a Friend
        </Button>
        <Button
          isFullWidth
          isFormBtn
          isGray
          onClick={() => routeRefer(true)}
          className={JobOpeningButtonClasses}
        >
          All job openings
        </Button>
      </div>
    </div>
  );
});
