import { Link } from 'react-router-dom';

import { ReactComponent as DarkLogoIcon } from 'assets/logo-white.svg';
import { ReactComponent as LogoIcon } from 'assets/logo.svg';

import FEATURE_FLAGS, { getStringFeatureFlagValue } from '../utils/featureFlags';
import { BrandConfig } from './brand-config';

const config: BrandConfig = {
  brandName: 'X-Team',
  appName: 'XHQ',
  introText: (
    <>
      You’re one click away from the XHQ. <br />
      Login using your x-team.com account.
    </>
  ),
  logo: LogoIcon,
  logoDarkMode: DarkLogoIcon,
  getLogoFeatureFlag: () => getStringFeatureFlagValue(FEATURE_FLAGS.xTeamLogo),
  useLogoFromFeatureFlag: true,
  logoSize: {
    width: '3rem',
    height: '3rem',
  },
  flags: {
    companyLogoEnabled: true,
    unleashEnabled: true,
    referEnabled: true,
    vaultEnabled: false,
    bountiesEnabled: false,
    xtuUniverseEnabled: true,
    legendsEnabled: true,
    legacyEnabled: true,
    housesEnabled: true,
    eventsEnabled: true,
    betsEnabled: true,
    seasonsEnabled: true,
    profileEnabled: true,
    outpostEnabled: true,
    privacyPolicyEnabled: true,
    vacationRequestEnabled: true,
    companiesEnabled: true,
    departmentEnabled: true,
    projectExpensesEnabled: true,
    invoiceSurveyEnabled: true,
    invoiceTermsEnabled: true,
    rateUpdateRequests: true,
    giveCoinsEnabled: false,
  },
  startPage: 'communityDashboard',
  handbookUrl: 'https://handbook.x-team.com',
  xtuUniversalUrl: 'https://xtu.x-team.com',
  outpostUrl: 'https://www.outpost.x-team.com/',
  myProfileTitle: 'My Jobs+ Account',
  myProfileUrl: 'https://jobs.x-team.com/',
  supportDeskUrl: 'https://x-team.slack.com/archives/CCSHSBUUT',
  faqIntro: `Do you have any questions about who you can reach out to when you encounter a problem? No
  worries, we've got you covered with a handy list of the most common questions / scenarios
  and the awesome folks who are there to help you out! If your scenario isn't on the list,
  don't worry at all. The Wizards are always eager and ready to support you, don’t hesitate
  to send a DM 😊
  `,
  faq: [
    {
      title:
        "My contract says I'm working 40 hours but I only worked 35 hours this week. How much can I charge?",
      content: ` It's crucial to only invoice for the hours you've worked. The hours specified in your contract
      represent the maximum, not the minimum number of hours you are engaged by X-Team.If there are public
      holidays or you need to take time off for personal reasons, please refrain from billing for that time.`,
    },
    {
      title: 'Can I bill for hours above 40 hours per week?',
      content: `You must not work or bill above 40 hours a week (or the maximum amount listed under your contract)
      unless you have direct approval to do so.`,
    },
    {
      title: 'If I miss the invoice cut-off, will I still be paid on time?',
      content: "If you're late to submit your invoice you will  get paid on the next payment date.",
    },
    {
      title:
        'How can I request a rate increase and what is taken into account when approving this?',
      content: 'This process is in the works. Stay tuned!',
    },
    {
      title: 'Is there a problem if I submit my invoice before the end of the period?',
      content:
        'Just make sure your hours are accurately logged and reach out to your X-Team manager for approval',
    },
    {
      title: 'I`ve made a mistake on my invoice. What should I do?',
      content: `If you're a Core member, please let your Team Manager know. If you're working on a partner project,
      it's super important to reach out to your CSM or Sales Director right away. They'll make sure to pass the info
      along to Finance or make any necessary adjustments in XHQ.`,
    },
    {
      title: 'What if I get an error message saying “Your hourly rate hasn`t been set yet”?',
      content:
        'Contact your Customer Success Manager (CSM) to have your rate set in XHQ for normal invoicing.',
    },
    {
      title: 'Who to contact for invoice, or XHQ issues?',
      content: 'Reach out to #support-desk for assistance',
    },
    {
      title: 'I lost access to my X-Team account. What should I do?',
      content: (
        <>
          If you ever require assistance with your accounts, please take a moment to complete this{' '}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSf9LmxIUSpLm5sSeSKzCoq2ec2klcK2SZVs7pbYMADBNGGY4g/viewform"
            title="Open Form"
          >
            form
          </a>{' '}
          . Following that, feel free to get in touch with Josh Johnston, or any of our friendly
          Wizards. We're here to help!
        </>
      ),
    },
    {
      title: `Who can I raise general wellbeing issues with?`,
      content: `Every member of our team has a wonderful Wizard who is there to listen and the team is there
      for anything you wish to discuss. Please note that whilst our Wizards are a lovely bunch, they are not
      professional counsellors and there is a limit to which they are able to provide tailored support.
      Should you have personal circumstances in your life requiring specific mental health or emotional support,
      we strongly encourage you to seek professional help. You are able to utilise  our Well-Being Unleash+
      category to seek support if you need it.`,
    },
    {
      title: `What if I have a complaint about someone at X-Team?`,
      content: `We are committed to respect at X-Team. If you feel a member of the team has done something that
      doesn't align with our values, please raise this with your Wizard. Alternatively, for serious issues
      (ie harassment, bullying, discrimination), please contact Natasha Romas directly.`,
    },
    {
      title: `If I need X-Team to sign official papers, what's the process`,
      content: `Just reach out to verify@x-team.com for any document needs. Please keep in mind that for contractors
      working with X-Team, we're unable to sign off as an 'employer' - we can only attest to the contractual relationship
      you have with X-Team.`,
    },
    {
      title: `Do I get PTO?`,
      content: `We don't provide Paid Time Off (PTO) because of our contractor setup. As independent contractors, you have
      the freedom to manage your own schedule and work terms, which is pretty awesome, right? Offering PTO could get a bit
      tricky legally and tax-wise, and it might also affect your flexibility and compensation. Your hard work means a lot to us,
      and we're all about staying on the right side of the law while still keeping things flexible for you.`,
    },
    {
      title: `X-Outpost & X-Summit`,
      content: (
        <>
          <ul>
            <li>
              Henrique is our main contact for all in-person events. Reach out to him should you
              have questions about any events upcoming.
            </li>
            <li>
              For information on the outposts please visit:{' '}
              <a href="https://www.outpost.x-team.com/information" title="X-Outpost">
                https://www.outpost.x-team.com/information
              </a>{' '}
            </li>
            <li>X-Summit information will be released via Slack closer to the event</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Who to contact for The Vault issues?',
      content: `Reach out to Christian Borlenghi for any Vault-related inquiries, including purchases,
      order tracking, or size help.`,
    },
    {
      title: 'Do I have to pay tax on Vault purchases?',
      content: ` In some locations, import tax may be applied to claims made on the vault. X-Team does not cover
      these costs directly however you are able to claim this expense under Unleash+`,
    },
    {
      title: 'How can I make sure I stay safe from scams or fraudulent requests here at X-Team?',
      content: `It's important to stay vigilant when it comes to unsolicited requests, especially
      if you're new to X-Team. Always double-check the identity of anyone you're not familiar with
      and never share personal info or do tasks without verifying properly. If you receive a request
      through any channels, feel free to double-check with someone you trust before proceeding.
      It's completely fine to take that extra step to ensure everything is legitimate. And if you
      ever spot any suspicious online activity, don't hesitate to report it right away to your Wizards.
      Quick reporting helps keep everyone, including yourself, safe from potential fraud.
      `,
    },
    {
      title: `What should I do if I come across something fishy online?`,
      content: `If you ever spot any suspicious online activity, don't hesitate to report it
      right away to your Wizards. Quick reporting helps keep everyone, including yourself,
      safe from potential fraud.`,
    },
    {
      title: `How does X-Team go about preventing scams and fraud, especially for newcomers?`,
      content: `At X-Team, we take the safety of our community seriously. We make sure all our
       members, especially new recruits, are educated on spotting scams. Plus, we've got
       security measures in place to maintain a secure environment for everyone. At X-Team,
       we value transparency and open communication. If someone tells you,
       "you shouldn't share this with anyone," and they can't provide a valid reason or prove
       their identity, it's wise to assume it might be a scam and report it. Your vigilance
       helps keep our community safe and secure.
      `,
    },
  ],
  contactContentForInvoices: (
    <>
      Don't see your project listed? <Link to="/help">Click here to get help</Link>
      <br />
      Couldn't find the answer? DM&nbsp;
    </>
  ),
  contactsListForInvoices: [
    { name: 'your Wizard' },
    { name: '#support-desk', url: 'slack://channel?team=T0257R0RP&id=CCSHSBUUT' },
  ],
  preferences: {
    birthdayPreferenceLabel: 'Inform other users about my birthday on #birthdays',
  },
  contractOptions: {
    individual: 'I contracted with X-Team as individual (the contract is in my personal name)',
    company: `I contracted with X-Team through a Company (the contract is in my company’s name)`,
    isRequired: true,
  },
  isXWP: false,
};

export { config };
