import { brandConfig } from 'brands';
import { Header } from 'design-system';

import { paymentData } from 'design-system/utils';
import { RATE_UPDATE_REASON, SortHandler, UserRole } from 'types/types';

export const RATE_UPDATE_REASONS = {
  [RATE_UPDATE_REASON.ANNUAL_PERFORMANCE]: 'Rate review.',
  [RATE_UPDATE_REASON.NEW_PROJECT]: 'New role/project.',
  [RATE_UPDATE_REASON.COUNTER_OFFER]: 'Counter offer needed to be made.',
  [RATE_UPDATE_REASON.OTHER]: 'Other',
};

export const GOOGLE_READY = 'GOOGLE_READY';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export const USER_DATA_UPDATED = 'USER_DATA_UPDATED';

export const PAYMENT_METHOD = {
  PAYPAL: 'paypal',
  AUSTRALIAN_LOCAL_BANK_TRANSFER: 'australian_local_bank_transfer',
  US_LOCAL_BANK_TRANSFER: 'us_local_bank_transfer',
  US_INTERNATIONAL_WIRED_TRANSFER: 'us_international_wired_transfer',
  EU_LOCAL_BANK_TRANSFER: 'european_local_bank_transfer',
  OTHER_AUD: 'other_aud',
  OTHER_USD: 'other_usd',
} as const;

export const INVOICE_PAYMENT_TYPE = {
  AIRWALLEX: 'airwallex',
  NON_AIRWALLEX: 'non-airwallex',
} as const;

export const SHARED_ACCOUNT_FIELDS = {
  paymentAccountOwnerName: { label: 'Account Owner Name', type: 'text' },
  paymentAccountOwnerAddress: { label: 'Account Owner Street', type: 'text' },
  paymentAccountOwnerCity: { label: 'Account Owner City', type: 'text' },
  paymentAccountOwnerZipcode: { label: 'Account Owner ZIP code', type: 'text' },
  paymentAccountOwnerCountry: { label: 'Account Owner Country', type: 'dropdown' },
};

export const paymentMethodsMap = paymentData;

export const paymentWideFields = ['paymentBankAddress', 'paymentAccountOwnerAddress'] as const;

export const INVOICE_STATUS = {
  APPROVED: 'approved',
  SUBMITTED: 'submitted',
  PAID: 'paid',
  DRAFT: 'draft',
  QUEUED: 'queued',
  PROCESSING_PAYMENT: 'processing_payment',
  FAILED: 'failed',
} as const;

export const PAYMENT_BUTTON_LABELS = {
  PROCESS_PAYMENT: 'Process Payment',
  PAY: 'Pay',
  CHANGE_TO_PAID: 'Change to Paid',
};

export const ACTIVITY_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
} as const;

export const mapStatusToBoolean = {
  true: true,
  false: false,
} as const;

export const STATUS_BOOLEAN = {
  ACTIVE: true,
  INACTIVE: false,
} as const;

export const mapBooleanToStatus = {
  [String(STATUS_BOOLEAN.ACTIVE)]: 'Active',
  [String(STATUS_BOOLEAN.INACTIVE)]: 'Inactive',
} as const;

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;

export const mapStatusToLabel = {
  [STATUS.ACTIVE]: 'Active',
  [STATUS.INACTIVE]: 'Inactive',
} as const;

export const mapUserStatusToLabel = {
  [String(ACTIVITY_STATUS.ACTIVE)]: 'Active',
  [String(ACTIVITY_STATUS.INACTIVE)]: 'Inactive',
} as const;

export const mapStatusToId = {
  [STATUS.ACTIVE]: 1,
  [STATUS.INACTIVE]: 2,
} as const;

export const MISSION_REWARD = {
  COIN: 'coin',
  STAR: 'star',
  VAULT_ITEM: 'vault item',
} as const;

// @todo fetch from the API
export const activityStatuses = [
  {
    label: 'Active',
    value: ACTIVITY_STATUS.ACTIVE,
    className: 'statusPaid',
  },
  {
    label: 'Inactive',
    value: ACTIVITY_STATUS.INACTIVE,
    className: 'statusApproved',
  },
] as const;

export const NOTES_STATUS = {
  VIEW_ALL: false,
  OPEN_NOTES_ONLY: true,
};

export const notesSatuses = [
  {
    label: 'View all',
    value: NOTES_STATUS.VIEW_ALL,
  },
  {
    label: 'Open notes only',
    value: NOTES_STATUS.OPEN_NOTES_ONLY,
  },
];

// @todo fetch from the API
export const confirmationStatuses = [
  {
    label: 'Approved',
    value: INVOICE_STATUS.APPROVED,
    className: 'statusApproved',
  },
  // {
  //   label: 'Disapproved',
  //   value: PAYMENT_STATUS.DISAPPROVED,
  //   className: 'statusSubmitted'
  // },
  {
    label: 'Submitted',
    value: INVOICE_STATUS.SUBMITTED,
    className: 'statusSubmitted',
  },
  { label: 'Paid', value: INVOICE_STATUS.PAID, className: 'statusPaid' },
] as const;

export const BREAKPOINTS = {
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
} as const;

export const DRAG_EVENTS = {
  start: 'start',
  end: 'end',
} as const;

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS';
export const ADD_MODAL_TOAST = 'ADD_MODAL_TOAST';
export const REMOVE_MODAL_TOAST = 'REMOVE_MODAL_TOAST';
export const REMOVE_ALL_MODAL_TOASTS = 'REMOVE_ALL_MODAL_TOASTS';

export const VAULT_ITEM_TYPES = {
  NORMAL_ITEM: 'normal item',
  CHARITY: 'charity',
  CUSTOM_CHARITY: 'custom-charity',
  ARENA_PASS: 'arena-pass',
  PRINTFUL: 'printful',
  EXPERIENCES: 'experiences',
  DIGITAL: 'digital',
  BOUNTIES_COLLECTION_PASS: 'bounties-collection-pass',
  DIGITAL_GIFT_CARD: 'digital gift-card',
} as const;

export const VAULT_ITEM_GOALS = {
  NONE: 'none',
  PURCHASES_GOAL: 'purchases goal',
  VALUE_GOAL: 'value goal',
} as const;

export const RARITY_TYPES = {
  COMMON: 'common',
  RARE: 'rare',
  LEGENDARY: 'legendary',
} as const;

export const DEFAULT_REFERRAL_REIMBURSEMENT_RATE = 500;

export const DEBOUNCE_WAIT_INTERVAL = 500;

export const USER_ROLE = {
  USER: 'user',
  SUPER_ADMIN: 'super admin',
  ADMIN: 'admin',
  COMMUNITY_TEAM: 'community team',
  MANAGER: 'manager',
  PEOPLE_OPS: 'people ops',
  RECRUITER: 'recruiter',
  SUPER_WIZARD: 'super-wizard',
} as const;

export const DISABLED_PAGES_EXCLUSION: UserRole[] = [
  USER_ROLE.USER,
  USER_ROLE.COMMUNITY_TEAM,
  USER_ROLE.MANAGER,
  USER_ROLE.PEOPLE_OPS,
  USER_ROLE.RECRUITER,
];

export const USER_TYPE = {
  CAVALRY: 'cavalry',
  DEVELOPER: 'developer',
  OTHER: 'other',
} as const;

export const INVOICE_ITEM_TYPES = {
  DEDUCTION: 'deduction',
  MANUAL_DEDUCTION: 'manual_deduction',
  MANUAL_BONUS: 'manual_bonus',
} as const;

export const INVOICE_CATEGORY = {
  CONTENT_CREATION: 'Content Creation',
  GST: 'GST',
  HOURS_WORKED: 'Hours Worked',
  PER_DIEM: 'Per Diem',
  REFERRAL: 'Referral',
  SOFTWARE: 'Software',
  TRAVEL_COSTS: 'Travel Costs',
  UNLEASH_PLUS: 'Unleash+',
  OTHER: 'Other',
} as const;

export const EXPENSE_CATEGORY = {
  HOURS_WORKED_NON_BILLABLE: 'Hours Worked (non-billable)',
  PER_DIEM_NON_BILLABLE: 'Per Diem (non-billable)',
  REFERRAL_NON_BILLABLE: 'Referral (non-billable)',
  TRAVEL_COSTS_NON_BILLABLE: 'Travel Costs (non-billable)',
  UNLEASH_NON_BILLABLE: 'Unleash+ (non-billable)',
  SUBSCRIPTIONS_NON_BILLABLE: 'Subscriptions (non-billable)',
  CONFERENCES_NON_BILLABLE: 'Conferences (non-billable)',
  TOOLS_NON_BILLABLE: 'Tools (non-billable)',
  EVENTS_NON_BILLABLE: 'Events (non-billable)',
  OTHER_EXPENSES_NON_BILLABLE: 'Other Expenses (non-billable)',
  CONFERENCES_BILLABLE: 'Conferences (billable)',
  EVENTS_BILLABLE: 'Events (billable)',
  PER_DIEM_BILLABLE: 'Per Diem (billable)',
  SUBSCRIPTIONS_BILLABLE: 'Subscriptions (billable)',
  TOOLS_BILLABLE: 'Tools (billable)',
  TRAVEL_COSTS_BILLABLE: 'Travel Costs (billable)',
  HOURS_WORKED_BILLABLE: 'Hours Worked (billable)',
} as const;

// This is the old scale.
export const DEPRECATED_MOOD_FACE = {
  VERYSAD: '-2',
  SAD: '-1',
  NEUTRAL: '0',
  HAPPY: '1',
  VERYHAPPY: '2',
} as const;

export const MOOD_FACE = {
  VERYSAD: '1',
  SAD: '2',
  NEUTRAL: '3',
  HAPPY: '4',
  VERYHAPPY: '5',
} as const;

export const MOOD_FACE_VALUE = {
  verySad: '1',
  sad: '2',
  neutral: '3',
  happy: '4',
  veryHappy: '5',
} as const;

export const MOOD_FACE_VALUE_TO_ID = {
  [String(MOOD_FACE_VALUE.verySad)]: 'verySad',
  [String(MOOD_FACE_VALUE.sad)]: 'sad',
  [String(MOOD_FACE_VALUE.neutral)]: 'neutral',
  [String(MOOD_FACE_VALUE.happy)]: 'happy',
  [String(MOOD_FACE_VALUE.veryHappy)]: 'veryHappy',
};

export const HEALTH_CHECK_INTERVAL = 5000;

export const VACATION_REQUEST_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
} as const;

export const vacationRequestStatusMap = {
  [VACATION_REQUEST_STATUS.PENDING]: 'Pending',
  [VACATION_REQUEST_STATUS.APPROVED]: 'Approved',
  [VACATION_REQUEST_STATUS.DECLINED]: 'Declined',
} as const;

export const vacationRequestStatusColorMap = {
  [VACATION_REQUEST_STATUS.PENDING]: 'featured-60',
  [VACATION_REQUEST_STATUS.APPROVED]: 'color-green2-bg',
  [VACATION_REQUEST_STATUS.DECLINED]: 'legendary-600',
} as const;

export const QUESTION_ANSWER_STATUS = {
  CONTACTED: 'contacted',
  NO_ACTION_REQUIRED: 'no action required',
} as const;

export const questionsAnswerStatusMap = {
  [QUESTION_ANSWER_STATUS.CONTACTED]: 'Contacted',
  [QUESTION_ANSWER_STATUS.NO_ACTION_REQUIRED]: 'No action required',
} as const;

export const questionsAnswerStatusColorMap = {
  [QUESTION_ANSWER_STATUS.CONTACTED]: 'featured-60',
  [QUESTION_ANSWER_STATUS.NO_ACTION_REQUIRED]: 'legendary-600',
} as const;

export const BANKING_DETAILS_UPDATE_WARNING_THRESHOLD_DAYS = 31;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BUSINESS_DAYS_UNTIL_INVOICE_SUBMISSION = 1;

export const NATIONALITY_REGEX = /([a-zA-Z]){2,}/;
export const PHONE_REGEX = /^(\+?\d{1,3}[\s.-]?)?(\(?\d{2,4}\)?)?[\s.-]?\d{2,4}[\s.-]?\d{4}$/;

export const STRING_DATE_REGEX = /^(\d{4}-\d{2}-\d{2}T[\d:.]{12}Z)$/;

export const YOUTUBE_VIDEO_STATE = {
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
};

export const DATE_FORMAT_UTC = 'YYYY-MM-DDT00:00:00.000Z';
export const FNS_FORMAT_MONTH_YEAR = 'MMMM yyyy';
export const FNS_DATE_FORMAT_UTC = 'yyyy-MM-ddT00:00:00.000Z';
export const DATE_FORMAT_SHORT = 'YYYY-MM-DD';
export const FNS_DATE_FORMAT_SHORT = 'yyyy-MM-dd';
export const DATE_FORMAT_MONTH_LABEL = 'MMMM YYYY';
export const DATE_FORMAT_MONTH_LABEL_SHORT = 'MMM YY';
export const DATE_FORMAT_MONTH_DAY_LABEL = 'MMM DD';
export const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';
export const DISPLAY_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} hh:mm A`;
export const FNS_DISPLAY_DATE_TIME_FORMAT = 'dd MMM yyyy';
export const FNS_LONG_DATE_TIME_FORMAT = 'EEEE, LLLL dd, yyyy hh:mm a';
export const FNS_DISPLAY_DATE_TIME_EXTENDED_FORMAT = `${FNS_DISPLAY_DATE_TIME_FORMAT} hh:mm a`;
export const FNS_RFC_3339 = `yyyy-MM-dd'T'HH:mm:ssXXX`;

export const BOUNTY_PATH_TYPES = {
  CREATIVE: 'Creative',
  EXPLORATION: 'Exploration',
  GROWTH: 'Growth',
  FITNESS: 'Fitness',
  GAMER: 'Gamer',
  CLUBS: 'Clubs',
  UNLEASHER: 'Unleasher',
};

export const PROJECT_TYPES = {
  EXTERNAL: 'external',
  INTERNAL_BENCH: 'internal-bench',
  INTERNAL_OPERATIONS: 'internal-operations',
} as const;

export const CURRENCIES = {
  USD: 'USD',
  CAD: 'CAD',
  EUR: 'EUR',
  AUD: 'AUD',
  BRL: 'BRL',
} as const;

export const DateFilterTypeName = {
  ALL: 'ALL TIME',
  TODAY: 'TODAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  RANGE: 'RANGE',
};

export const CompanyFilterTypeName = {
  XWP: 'XWP',
  XTEAM: 'X-TEAM',
  ALL_COMPANIES: 'ALL',
};

export const BOUNTY_TYPE = {
  STANDARD: 'STANDARD',
  USER_CREATED: 'USER_CREATED',
  PRIVATE: 'PRIVATE',
} as const;

export const BOUNTY_LIMIT = 10;

export const MANAGE_LISTS_MODAL = 'MANAGE_LISTS_MODAL';
export const ADD_BOUNTY_TO_LIST_MODAL = 'ADD_BOUNTY_TO_LIST_MODAL';

export const DEFAULT_UNLEASH_BUDGET = 2500;

export const CURRENCIES_SYMBOLS: { [key: string]: string } = {
  [CURRENCIES.USD]: '$',
  [CURRENCIES.CAD]: '$',
  [CURRENCIES.EUR]: '€',
  [CURRENCIES.AUD]: '$',
};

export const FEED = {
  USER_FEED: 'user',
  GLOBAL_FEED: 'global',
  PINNED_FEED: 'pinned',
  SUGGESTED: 'SUGGESTED',
};

export const USER_ID_PREFIX = 'XHQ';

export enum CONTRACTING_OPTIONS {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export const CONTRACTING_OPTIONS_LABELS = {
  [CONTRACTING_OPTIONS.INDIVIDUAL]:
    'I contracted with X-Team as individual (the contract is in my personal name)',
  [CONTRACTING_OPTIONS.COMPANY]:
    'I contracted with X-Team through a Company (the contract is in my company’s name)',
};

export const EMPLOYMENT_TYPE = {
  EMPLOYEE: '1',
  CONTRACTOR: '2',
  COMPANY: '3',
};

export const EMPLOYMENT_TYPE_NAME = {
  EMPLOYEE: 'Employee',
  CONTRACTOR: 'Contractor',
  COMPANY: 'Company',
};

export const PAYMENT_TYPE = {
  US_LOCAL_PAYMENT: 1,
  EUR_LOCAL_PAYMENT: 2,
  OTHER_PAYMENT_AUD: 3,
  OTHER_PAYMENT_USD: 4,
  PAYPAL: 5,
  AUD_LOCAL_PAYMENT: 6,
  INTERNATIONAL_WIRE_PAYMENT: 7,
};

export const PAYMENT_VIA = {
  AIRWALLEX: '1',
  NON_AIRWALLEX: '2',
};

export const SUBMIT_INVOICE_STEPS = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4,
  STEP_5: 5,
};

export const PROGRESS_INDICATOR_DATA = [
  {
    label: 'Submit Invoice',
    numberStep: 1,
  },
  ...(brandConfig.isXWP
    ? []
    : [
        {
          label: 'Survey',
          numberStep: 2,
        },
      ]),
  {
    label: 'Referral',
    numberStep: 3,
  },
  {
    label: 'Confirmation',
    numberStep: 4,
  },
];

export const SUBMIT_INVOICE_PERIODS = {
  FIRST_HALF: 'firstHalf',
  SECOND_HALF: 'secondHalf',
  FULL_MONTH: 'default',
} as const;

const SUBMIT_INVOICE_STEP_HEADER_TITLE = 'SUBMIT INVOICE';

export const SUBMIT_INVOICE = {
  PROGRESS_INDICATOR_DATA,
  TITLE: SUBMIT_INVOICE_STEP_HEADER_TITLE,
  CURRENT_PROGRESS: 1,
  CONTENT: [
    {
      step: 'Step 0',
      title: 'Choose Company',
      description: 'Choose the company you are submitting your invoice to',
    },
    {
      step: 'Step 1',
      title: 'Invoice setup',
      description: 'Invoice Number #',
    },
    {
      step: 'Step 2',
      title: 'Hours',
      description: 'How many hours did you work this period?',
      warning: `If you have expenses, don't include them as hours. Insert expenses on the next step.`,
      questions: [
        {
          question: `Don't see your project listed? `,
          link: 'Click here to get help.',
          url: '/help',
        },
      ],
    },
    {
      step: 'Step 3',
      title: 'Reimbursements & Unleash+ Claims',
      description:
        'Use this step to submit an Unleash+ claim, get reimbursed for things like client travel expenses, or get paid for a Referral fee.',
    },
    {
      step: 'Step 4',
      title: 'Banking Info',
      description: 'Confirm your baking information',
    },
    {
      step: 'Step 5',
      title: 'Notes',
      description: 'Do you have anything to note?',
    },
  ],
};

export const SURVEY_INVOICE = {
  PROGRESS_INDICATOR_DATA,
  TITLE: SUBMIT_INVOICE_STEP_HEADER_TITLE,
  CURRENT_PROGRESS: 2,
  CONTENT: [
    {
      step: 'THE-1-ON-1',
      title: 'We’re here to support you',
      description:
        'Consider taking advantage of your Unleash+ budget to help get more energized, relax more, do more of what you love, get more active/healthy, utilize therapy or meditation, or more ways we can financially support your wellbeing.',
    },
    {
      step: 'Step 1',
      title: 'Invoice setup',
      description: 'Invoice Number #12',
      active: true,
    },
    {
      step: 'Step 2',
      title: 'Hours',
      description: 'How many hours did you work this period?',
    },
  ],
};

export const REFER_A_FRIEND_FORM = {
  name: '',
  email: '',
  linkedin: '',
  beacon: '',
  checkQuestions: [
    { question: 'Have you worked with them directly?', answer: false },
    { question: 'Would you vouch for them?', answer: false },
    { question: 'Would you consider them to be Culture Fit?', answer: false },
    { question: 'Are their skills of X-Team standard?', answer: false },
    { question: 'Do they want to join X-Team?', answer: false },
  ],
  openQuestions: [
    { question: 'How do you know them?', answer: '' },
    { question: 'What is their main skill set?', answer: '' },
    { question: 'What is their main weak point?', answer: '' },
  ],
};

export const REFERRALS_INVOICE = {
  PROGRESS_INDICATOR_DATA,
  TITLE: SUBMIT_INVOICE_STEP_HEADER_TITLE,
  CURRENT_PROGRESS: 3,
};

export const MOOD_SCALE_CHANGE = '2022-06-24';

export const CONFIRMATION_INVOICE = {
  PROGRESS_INDICATOR_DATA,
  TITLE: SUBMIT_INVOICE_STEP_HEADER_TITLE,
  CURRENT_PROGRESS: 4,
};

export const PAGE_SIZE_S = 10;
export const PAGE_SIZE_M = 40;
export const PAGE_SIZE_L = 100;

export const BETS_COLUMN_HEADERS: Header[] = [
  {
    children: 'Title',
    sortColumnName: 'title',
  },
  {
    children: 'Channel',
    sortColumnName: 'channel',
  },
  {
    children: 'Type',
    sortColumnName: 'type',
  },
  {
    children: 'Winning option',
    sortColumnName: 'startDate',
  },
  {
    children: 'Amount',
    sortColumnName: 'amount',
  },
  {
    children: '',
  },
];

export const ASSIGNMENTS_ITEMS_PER_PAGE = 10;

export const ASSIGNMENTS_COLUMN_HEADERS: Header[] = [
  {
    children: 'Project name',
    sortColumnName: '_project.name',
  },
  {
    children: 'Accounting Code',
    sortColumnName: '_project._accountingCode.name',
  },
  {
    children: 'Rate',
    sortColumnName: 'rate',
  },
  {
    children: 'Active Period',
    sortColumnName: 'startDate',
  },
  {
    children: 'Status',
    sortColumnName: 'isActive',
  },
  {
    children: 'Notes',
    sortColumnName: 'notes',
  },
  {
    children: 'Hours limit per business day',
    sortColumnName: 'hoursLimit',
  },
  {
    children: '',
  },
];

export const ASSIGNMENTS_HISTORY_COLUMN_HEADERS: Header[] = [
  {
    children: '',
  },
  {
    children: 'Change',
    sortColumnName: 'oldValue',
  },
  {
    children: 'Manager',
    sortColumnName: '_createdByUser.displayName',
  },
  {
    children: 'Reason',
    sortColumnName: 'description',
  },
  {
    children: 'Updated At',
    sortColumnName: 'addedAt',
  },
];

export const ANSWERS_COLUMN_HEADERS: Header[] = [
  {
    children: 'Name',
    sortColumnName: '_owner.displayName',
  },
  {
    children: 'Invoice #',
    sortColumnName: '',
  },
  {
    children: 'Project name(s)',
    sortColumnName: '',
  },
  {
    children: 'Manager',
    sortColumnName: '',
  },
];

export const ANSWER_QUESTIONS_COLUMN_HEADERS: Header[] = [
  {
    children: '',
  },
  {
    children: 'Question',
    sortColumnName: '',
  },
  {
    children: 'Answer',
    sortColumnName: '',
  },
  {
    children: 'Action',
    sortColumnName: '',
  },
  {
    children: 'Status',
    sortColumnName: '',
  },
  {
    children: 'Contacted by',
    sortColumnName: '',
  },
];

const NUMBER_SORT_HANDLER: SortHandler<number> = {
  match(a, b) {
    return typeof a === 'number' && typeof b === 'number';
  },
  compare(a, b) {
    return a - b;
  },
};

const STRING_SORT_HANDLER: SortHandler<string> = {
  match(a, b) {
    return typeof a === 'string' && typeof b === 'string';
  },
  compare(a, b) {
    return a.localeCompare(b);
  },
};

const BOOLEAN_SORT_HANDLER: SortHandler<string> = {
  match(a, b) {
    return typeof a === 'boolean' && typeof b === 'boolean';
  },
  compare(a, b) {
    return Number(a) - Number(b);
  },
};

const DATE_SORT_HANDLER: SortHandler<Date> = {
  match(a, b) {
    return a instanceof Date && b instanceof Date;
  },
  compare(a, b) {
    return a.getTime() - b.getTime();
  },
};

export const SORT_HANDLERS: SortHandler<any>[] = [
  NUMBER_SORT_HANDLER,
  STRING_SORT_HANDLER,
  BOOLEAN_SORT_HANDLER,
  DATE_SORT_HANDLER,
];

export const DATE_RANGE_PICKER_NUMBER_OF_MONTHS = 2;
